import { EVENT_ID, WEIGHT_ID } from "./const-event";

export const formatIndividual = (participant, mode, saveParticipant) => {
  if (mode === 1) {
    // list (GET)
    let event = new Set();
    const eventIds = Object.keys(participant.eventInfo);
    if (eventIds.length) {
      eventIds.forEach((eventId) => {
        let eventName = Object.keys(EVENT_ID).find(
          (key) => EVENT_ID[key].id === Number(eventId)
        );
        event.add(eventName.split(" ")[2]);
      });
    }
    event = [...event];

    let weight = "";
    if (participant.weightClassId) {
      weight = Object.keys(WEIGHT_ID[participant.gender]).find(
        (key) =>
          WEIGHT_ID[participant.gender][key].id === participant.weightClassId
      );
    }

    let idnumber =
      participant.identityNumber && participant.identityNumber.split("-");

    return {
      participantId: participant.participantId,
      eventInfo: participant.eventInfo,
      name: participant.name,
      sex: participant.gender, // 남성,여성인지 체크
      foreigner: participant.isForeigner ? ["Y"] : ["N"],
      nationality: participant.nationality,
      idnumber: participant.identityNumber
        ? [idnumber[0], "-", idnumber[1]]
        : ["", "-", ""],
      phoneNumber: participant.phoneNumber || "",
      event: event,
      weight: weight,
      editable: false,
    };
  }

  if (mode === 2 || mode === 3) {
    //regist (POST) //modify (PUT)
    let identityNumber = participant.idnumber.join("");
    if (identityNumber === "-") identityNumber = null;

    let eventId = [];
    participant.event.forEach((eventname) => {
      let eventKey = "개인전 " + participant.sex + " " + eventname;
      eventId.push(EVENT_ID[eventKey].id);
    });

    let weightClassId = participant.weight
      ? WEIGHT_ID[participant.sex][participant.weight].id
      : null;

    let sendData = {
      name: participant.name.trim(),
      gender: participant.sex,
      isForeigner: participant.foreigner.length > 0 ? true : false,
      nationality: participant.nationality,
      identityNumber: identityNumber,
      phoneNumber: participant.phoneNumber.trim(),
      // eventIds: eventId,
      weightClassId: weightClassId,
    };

    if (mode === 2) {
      return { ...sendData, eventIds: eventId };
    }

    if (mode === 3) {
      //modify (PUT)
      // foreigner 처리 달라짐
      const isNullData = (data) => {
        if (data === null || data === "" || data === undefined) return true;
        else return data;
      };

      let isParticipantChange = false;
      let isEventChange = false;
      let isWeightClassChange = false;
      if (
        sendData.name !== saveParticipant.name ||
        sendData.gender !== saveParticipant.gender ||
        sendData.isForeigner !== saveParticipant.isForeigner ||
        isNullData(sendData.nationality) !==
          isNullData(saveParticipant.nationality) ||
        isNullData(sendData.identityNumber) !==
          isNullData(saveParticipant.identityNumber) ||
        isNullData(sendData.phoneNumber) !==
          isNullData(saveParticipant.phoneNumber)
      ) {
        isParticipantChange = true;
      }

      let saveParticipantEventId = [];
      Object.keys(saveParticipant.eventInfo).forEach((event) => {
        saveParticipantEventId.push(Number(event));
      });

      if (
        JSON.stringify(eventId.slice().sort()) !==
        JSON.stringify(saveParticipantEventId.slice().sort())
      ) {
        isEventChange = true;
      }

      let eventInfo = participant.eventInfo;
      if (isEventChange) {
        eventInfo = {};
        //eventId 가 현재 선택한 Id
        eventId.forEach((eId) => {
          if (!saveParticipantEventId.includes(eId)) {
            eventInfo[eId] = null;
          } else {
            eventInfo[eId] = saveParticipant.eventInfo[eId];
          }
        });
      }

      if (
        isNullData(sendData.weightClassId) !==
        isNullData(saveParticipant.weightClassId)
      ) {
        isWeightClassChange = true;
      }

      if (!isParticipantChange && !isEventChange && !isWeightClassChange) {
        return false;
      }

      return {
        ...sendData,
        participantId: participant.participantId,
        isParticipantChange: isParticipantChange,
        isEventChange: isEventChange,
        isWeightClassChange: isWeightClassChange,
        eventInfo: eventInfo,
      };
    }
  }
};

export const formatTeam = (team, mode, saveTeam) => {
  if (mode === 1) {
    // list (GET)
    let eventName;
    if (team.eventId) {
      eventName = Object.keys(EVENT_ID).find(
        (key) => EVENT_ID[key].id === team.eventId
      );
    }

    const getWeight = (weightId, sex) => {
      let weight = "";
      if (weightId) {
        weight = Object.keys(WEIGHT_ID[sex]).find(
          (key) => WEIGHT_ID[sex][key].id === weightId
        );
      }
      return weight;
    };

    const getIdNumber = (identityNumber) => {
      if (!identityNumber) return ["", "-", ""];

      let idnumber = identityNumber.split("-");
      return [idnumber[0], "-", idnumber[1]];
    };

    let teamMembers = team.teamMembers.map((member) => {
      return {
        participantId: member.participantId,
        participantApplicationId: member.participantApplicationId,
        indexInTeam: member.indexInTeam,
        name: member.name,
        sex: member.gender,
        foreigner: member.isForeigner ? ["Y"] : ["N"],
        nationality: member.nationality,
        idnumber: getIdNumber(member.identityNumber),
        weight: getWeight(member.weightClassId, member.gender),
        phoneNumber: member.phoneNumber,
        editable: member.editable === false ? false : true,
      };
    });

    return {
      eventTeamNumber: team.eventTeamNumber,
      event: EVENT_ID[eventName].name,
      eventId: team.eventId,
      editable: false,
      teamMembers: teamMembers,
    };
  }

  if (mode === 2 || mode === 3) {
    let eventName;
    if (team.event) {
      eventName = Object.keys(EVENT_ID).find(
        (key) => EVENT_ID[key].name === team.event
      );
    }

    const getIdNumber = (idnumber) => {
      let identityNumber = idnumber.join("");
      if (identityNumber === "-") identityNumber = null;
      return identityNumber;
    };

    let sendData = {
      // eventTeamNumber: team.eventTeamNumber,
      eventId: EVENT_ID[eventName].id,

      teamMembers: team.teamMembers
        .filter((member) => member.editable)
        .map((member) => ({
          indexInTeam: member.indexInTeam,
          name: member.name,
          gender: member.sex,
          isForeigner: member.foreigner.length > 0 ? true : false,
          nationality: member.nationality,
          identityNumber: getIdNumber(member.idnumber),
          phoneNumber: member.phoneNumber,
          weightClassId: member.weight
            ? WEIGHT_ID[member.sex][member.weight].id
            : null,
        })),
    };

    if (mode === 2) {
      return sendData;
    }

    if (mode === 3) {
      const isNullData = (data) => {
        if (data === null || data === "" || data === undefined) return true;
        else return data;
      };

      let isChange = false;

      sendData.teamMembers = sendData.teamMembers.map((member) => {
        // participantId, isParticipantChange, participantApplicationId, isWeightClassChange
        const savedMember = saveTeam.teamMembers.filter(
          (saveMember) => saveMember.indexInTeam === member.indexInTeam
        )[0];

        let isParticipantChange = false;
        let isWeightClassChange = false;

        if (
          savedMember === undefined ||
          member.name !== savedMember.name ||
          member.gender !== savedMember.gender ||
          member.isForeigner !== savedMember.isForeigner ||
          isNullData(member.nationality) !==
            isNullData(savedMember.nationality) ||
          isNullData(member.identityNumber) !==
            isNullData(savedMember.identityNumber) ||
          isNullData(member.phoneNumber) !== isNullData(savedMember.phoneNumber)
        ) {
          isParticipantChange = true;
          isChange = true;
        }

        if (
          savedMember === undefined ||
          isNullData(member.weightClassId) !==
            isNullData(savedMember.weightClassId)
        ) {
          isWeightClassChange = true;
          isChange = true;
        }

        return {
          participantId: savedMember ? savedMember.participantId : null,
          participantApplicationId: savedMember
            ? savedMember.participantApplicationId
            : null,
          ...member,
          isParticipantChange: isParticipantChange,
          isWeightClassChange: isWeightClassChange,
        };
      });

      if (sendData.teamMembers.length !== saveTeam.teamMembers.length) {
        isChange = true;
      }

      if (!isChange) {
        return false;
      }

      return {
        eventTeamNumber: team.eventTeamNumber,
        ...sendData,
      };
    }
  }
};

export const formatSecond = (participant, mode, saveParticipant) => {
  if (mode === 1) {
    let idnumber =
      participant.identityNumber && participant.identityNumber.split("-");

    return {
      participantId: participant.participantId,
      eventInfo: participant.eventInfo,
      name: participant.name,
      sex: participant.gender, // 남성,여성인지 체크
      foreigner: participant.isForeigner ? ["Y"] : ["N"],
      nationality: participant.nationality,
      idnumber: participant.identityNumber
        ? [idnumber[0], "-", idnumber[1]]
        : [],
      phoneNumber: participant.phoneNumber,
    };
  }

  if (mode === 2 || mode === 3) {
    //regist (POST) //modify (PUT)
    let identityNumber = participant.idnumber.join("");
    if (identityNumber === "-") identityNumber = null;

    let sendData = {
      name: participant.name.trim(),
      gender: participant.sex,
      isForeigner: participant.foreigner.length > 0 ? true : false,
      nationality: participant.nationality,
      identityNumber: identityNumber,
      phoneNumber: participant.phoneNumber.trim(),
      // eventId: EVENT_ID["세컨"],
    };

    if (mode === 2) {
      return sendData;
    }

    if (mode === 3) {
      //modify (PUT)
      const isNullData = (data) => {
        if (data === null || data === "" || data === undefined) return true;
        else return data;
      };

      let isParticipantChange = false;
      if (
        sendData.name !== saveParticipant.name ||
        sendData.gender !== saveParticipant.gender ||
        sendData.isForeigner !== saveParticipant.isForeigner ||
        isNullData(sendData.nationality) !==
          isNullData(saveParticipant.nationality) ||
        isNullData(sendData.identityNumber) !==
          isNullData(saveParticipant.identityNumber) ||
        isNullData(sendData.phoneNumber) !==
          isNullData(saveParticipant.phoneNumber)
      ) {
        isParticipantChange = true;
      }

      if (!isParticipantChange) {
        return false;
      }

      return {
        ...sendData,
        participantId: participant.participantId,
        // isParticipantChange: isParticipantChange,
        // eventInfo: eventInfo,
      };
    }
  }
};

export const formatVolunteer = (participant, mode, saveParticipant) => {
  if (mode === 1) {
    let phoneNumber =
      participant.phoneNumber && participant.phoneNumber.split("-");

    return {
      participantId: participant.participantId,
      eventInfo: participant.eventInfo,
      name: participant.name,
      sex: participant.gender, // 남성,여성인지 체크
      phoneNumber: participant.phoneNumber
        ? [phoneNumber[0], "-", phoneNumber[1], "-", phoneNumber[2]]
        : [],
    };
  }

  if (mode === 2 || mode === 3) {
    //regist (POST) //modify (PUT)
    let phoneNumber = participant.phoneNumber.join("");
    if (phoneNumber === "--") phoneNumber = null;

    let sendData = {
      name: participant.name.trim(),
      gender: participant.sex,
      phoneNumber: phoneNumber,
      // eventId: EVENT_ID["자원봉사자"],
    };

    if (mode === 2) {
      return sendData;
    }

    if (mode === 3) {
      //modify (PUT)
      const isNullData = (data) => {
        if (data === null || data === "" || data === undefined) return true;
        else return data;
      };

      let isParticipantChange = false;
      if (
        sendData.name !== saveParticipant.name ||
        sendData.gender !== saveParticipant.gender ||
        isNullData(sendData.phoneNumber) !==
          isNullData(saveParticipant.phoneNumber)
      ) {
        isParticipantChange = true;
      }

      if (!isParticipantChange) {
        return false;
      }

      return {
        ...sendData,
        participantId: participant.participantId,
        // isParticipantChange: isParticipantChange,
        // eventInfo: eventInfo,
      };
    }
  }
};
