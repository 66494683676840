import React, { useEffect, useState, useContext } from "react";

import { TABLE_COLUMNS_SECOND_UNIVERSITY } from "../../shared/util/admin/admin-columns";
import { HttpContext } from "../../shared/context/http-context";
import { AuthContext } from "../../shared/context/auth-context";

import SubmitTable from "../../user/submit/components/SubmitTable";

import "./University.css";

const SecondUniversity = () => {
  const { sendRequest } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const [tableData, setTableData] = useState([]);

  const checkRefundHandler = async (event) => {
    event.preventDefault();
    event.persist();

    const idArray = event.target.id.split("-");
    const rowNum = Number(idArray[0].replace("nullrow", ""));
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/second/university?userId=${tableData[rowNum].userId}`,
        "POST",
        JSON.stringify({
          ...tableData[rowNum],
          refundConfirmed: !tableData[rowNum]["refundConfirmed"],
        }),
        {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        "환불확인 실패"
      );

      if (responseData.isSuccess) {
        setTableData((prevVal) => {
          let changeRow = { ...prevVal[rowNum] };
          changeRow["refundConfirmed"] = !changeRow.refundConfirmed;
          prevVal[rowNum] = changeRow;
          return [...prevVal];
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    const listSecondUniversity = async () => {
      try {
        let responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/second/university`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          "최종내역 로드 실패"
        );

        if (responseData.isSuccess && responseData.payload) {
          setTableData(
            // responseData.payload.registrationList.map((reg) => {
            responseData.payload.map((reg) => {
              if (!reg.finalSubmitted) {
                delete reg.refundConfirmed;
              }
              return reg;
            })
          );
        }
      } catch (err) {}
    };

    listSecondUniversity();
  }, [auth.token, sendRequest]);

  return (
    <div className="university second-university">
      <h2 className="admin-title">최종 내역 - 학교별</h2>
      <div className="description">
        * 음영처리된 학교는 2차제출 하지 않은 학교입니다
      </div>
      <SubmitTable
        columns={TABLE_COLUMNS_SECOND_UNIVERSITY}
        data={tableData}
        showNumber
        buttonHandler={checkRefundHandler}
        cssGrayBackground
      />
    </div>
  );
};

export default SecondUniversity;
