import React from "react";

const SubmitTableHeader = React.memo(({ showNumber, columns }) => {
  return (
    <React.Fragment>
      <colgroup>
        {columns.map((col, i) => (
          <col key={col.id} className={"table-col-" + i} />
        ))}
        {showNumber && <col className={"table-col-" + columns.length} />}
      </colgroup>
      <thead>
        <tr>
          {showNumber && <th></th>}
          {columns.map((col) => (
            <th key={col.id}>
              {col.name}
              <div className="table-header__subname">{col.subname}</div>
            </th>
          ))}
        </tr>
      </thead>
    </React.Fragment>
  );
});

export default SubmitTableHeader;
