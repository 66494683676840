import React, { useCallback, useEffect, useState, useContext } from "react";

import { HttpContext } from "../../../shared/context/http-context";
import { AuthContext } from "../../../shared/context/auth-context";

import DocuSubmitTable from "../components/DocuSubmitTable";
import {
  colInfo,
  colInfoVolunteer,
} from "../../../shared/util/docu/docu-columns";

import "./DocuSubmit.css";

const checkValidityFile = (file) => {
  // file type
  const fileType = file.type;
  if (!fileType.includes("image") && !fileType.includes("pdf")) {
    return {
      result: false,
      message: (
        <div>
          해당 파일은 이미지 파일이 아닙니다.
          <br />
          이미지(JPG,JPEG,PNG)나 PDF 파일을 업로드 해주세요.
        </div>
      ),
    };
  }

  // file size
  const fileSize = file.size;
  const limitsize = 1024 ** 2 * process.env.REACT_APP_FILE_LIMIT_SIZE_MB; // 10MB

  if (fileSize > limitsize) {
    return {
      result: false,
      message: (
        <div>
          {process.env.REACT_APP_FILE_LIMIT_SIZE_MB}MB 이하로 파일을
          업로드해주세요.
        </div>
      ),
    };
  }

  return { result: true };
};

const documentsColumn = colInfo.find((col) => col.id === "documents");
const documentsName = documentsColumn.columns.map((col) => col.name);
// const documentsName = ["증명사진", "서약서", "선수등록이력"];

const checkValidityFileMultiple = (file) => {
  // file name
  const fileName = file.name.toString();
  const fileSplits = fileName.split(/_|\./);

  if (!documentsName.includes(fileSplits[1].normalize("NFC"))) {
    return {
      result: false,
      message: (
        <div>
          "{fileName}"의 파일명 형식이 일치하지 않습니다.
          <br />
          일괄제출 시에는 "[이름]_[서류명]" 으로 파일명을 수정해주세요. (예시 :
          홍길동_증명사진)
        </div>
      ),
    };
  }

  // file type
  const fileType = file.type;
  if (!fileType.includes("image") && !fileType.includes("pdf")) {
    return {
      result: false,
      message: (
        <div>
          해당 파일은 이미지 파일이 아닙니다.
          <br />
          이미지(JPG,JPEG,PNG)나 PDF 파일을 업로드 해주세요.
        </div>
      ),
    };
  }

  // file size
  const fileSize = file.size;
  const limitsize = 1024 ** 2 * process.env.REACT_APP_FILE_LIMIT_SIZE_MB; // 1MB

  if (fileSize > limitsize) {
    return {
      result: false,
      message: (
        <div>
          {process.env.REACT_APP_FILE_LIMIT_SIZE_MB}MB 이하로 파일을
          업로드해주세요.
        </div>
      ),
    };
  }

  return { result: true };
};

const DocuSubmit = () => {
  const notification = (
    <React.Fragment>
      일괄제출할 경우에는 파일명을 아래와 같이 형식에 맞추어 제출바랍니다.{" "}
      <br></br>예) 홍길동_증명사진, 홍길동_서약서, 홍길동_선수등록이력,
      홍길동_학적부
    </React.Fragment>
  );

  const [participants, setParticipants] = useState({
    sunsuSeconds: [],
    volunteers: [],
  });

  const formatParticipant = (participant) => {
    const types = participant.types.join(",");

    let fileInfos = {};
    if (types === "자원봉사자") {
      const documentsColumn = colInfoVolunteer.find(
        (col) => col.id === "documents"
      );
      documentsColumn.columns.forEach((colInfo) => {
        const file = participant.fileInfos.find(
          (fileInfo) =>
            fileInfo.fileName &&
            fileInfo.fileName.normalize("NFC").includes(colInfo.name)
        );
        const fileNull = participant.fileInfos.find(
          (fileInfo) => fileInfo.fileName === null && !fileInfo.isSet
        );
        if (file) {
          fileInfos[colInfo.id] = { ...file, status: "제출완료" };

          if (file.isFileUncorrect === true) {
            fileInfos[colInfo.id] = {
              ...file,
              status: "재제출 필요",
            };
          }
        } else if (fileNull) {
          // fileName null 인거 찾아서 fileId로 사용
          fileNull.isSet = true;
          fileInfos[colInfo.id] = {
            fileId: fileNull.fileId,
            fileName: colInfo.name,
            status: "미제출",
          };
        }
      });

      return {
        participantId: participant.participantId,
        name: participant.name,
        identityNumber: participant.identityNumber,
        types: types,
        ...fileInfos,
        "docu-allSubmit": {
          status: participant.isAllFileConfirmed ? "제출완료" : "미제출",
        },
      };
    } else {
      const documentsColumn = colInfo.find((col) => col.id === "documents");

      documentsColumn.columns.forEach((colInfo) => {
        const file = participant.fileInfos.find(
          (fileInfo) =>
            fileInfo.fileName &&
            fileInfo.fileName.normalize("NFC").includes(colInfo.name)
        );

        const fileNull = participant.fileInfos.find(
          (fileInfo) => fileInfo.fileName === null && !fileInfo.isSet
        );

        if (file) {
          fileInfos[colInfo.id] = { ...file, status: "제출완료" };
          if (file.isFileUncorrect === true) {
            fileInfos[colInfo.id] = {
              ...file,
              status: "재제출 필요",
            };
          }
        } else if (fileNull) {
          // fileName null 인거 찾아서 fileId로 사용
          fileNull.isSet = true;
          fileInfos[colInfo.id] = {
            fileId: fileNull.fileId,
            fileName: colInfo.name,
            status: "미제출",
          };
        }
      });

      if (!participant.isForeigner) {
        delete fileInfos["docu5"];
      }

      return {
        participantId: participant.participantId,
        name: participant.name,
        identityNumber: participant.identityNumber,
        types: types,
        events: participant.events.join(","),
        ...fileInfos,
        "docu-allSubmit": {
          status: participant.isAllFileConfirmed ? "제출완료" : "미제출",
        },
      };
    }
  };

  const auth = useContext(AuthContext);
  const { sendRequest, setError } = useContext(HttpContext);

  // API
  const listHandler = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/file?userId=${auth.userId}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
        `데이터 로드 실패`
      );

      if (responseData.payload.participants) {
        let participantsData = {
          sunsuSeconds: [],
          volunteers: [],
        };

        responseData.payload.participants.forEach((participant) => {
          const participantData = formatParticipant(participant);
          if (participantData.types === "자원봉사자") {
            participantsData.volunteers.push(participantData);
          } else {
            participantsData.sunsuSeconds.push(participantData);
          }
        });

        setParticipants(participantsData);
      }
    } catch (err) {}
  }, [auth.userId, auth.token, sendRequest]);

  const submitOneFileHandler = useCallback(
    async (file, id) => {
      const { result, message } = checkValidityFile(file);
      if (!result) {
        setError({ title: "파일정보 확인", detail: message });
        return;
      }

      const idArray = id.split("-");
      const rowNum = Number(idArray[0].replace("row", ""));
      const docuIdx = idArray[2];

      try {
        let formData = new FormData();

        const participant = participants[idArray[3]][rowNum];
        const docuName =
          participant[docuIdx].fileName.split(".").length > 0
            ? participant[docuIdx].fileName.split(".")[0].split("_")[
                participant[docuIdx].fileName.split("_").length - 1
              ]
            : participant[docuIdx].fileName;
        const fileName =
          // auth.userId.toString() +
          auth.universityName +
          "_" +
          participant.name +
          "_" +
          docuName +
          "." +
          file.name.split(".")[1];

        formData.append("participantId", participant.participantId);
        const fileInfos = {
          fileInfos: [
            {
              fileId: participant[docuIdx].fileId,
              // fileName: participant[docuIdx].fileName,
              fileName: fileName,
            },
          ],
        };

        formData.append(
          "fileInfos",
          new Blob([JSON.stringify(fileInfos)], { type: "application/json" })
        );

        formData.append("files", file, fileName);

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/file`,
          "POST",
          formData,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          `파일 업로드 실패`
        );

        // // DUMMY DATA
        // const responseData = {
        //   isSuccess: true,
        //   message: null,
        //   payload: {
        //     fileInfos: [
        //       {
        //         fileId: 100,
        //         fileName: "서약서",
        //       },
        //     ],
        //   },
        // };

        if (responseData.isSuccess) {
          let participantsData = JSON.parse(JSON.stringify(participants));

          let updatedData = [...participantsData[idArray[3]]];
          let updatedRow = { ...updatedData[rowNum] };
          let updatedDocuAll = { ...updatedRow["docu-allSubmit"] };
          let updatedDocu = { ...updatedRow[docuIdx] };
          updatedDocu.status = "제출완료";
          updatedDocu.fileId = responseData.payload.fileInfos[0].fileId;

          updatedRow[docuIdx] = updatedDocu;
          updatedData[rowNum] = updatedRow;
          participantsData[idArray[3]] = updatedData;

          // 모든 파일 제출했는지 체크
          const documentsId = documentsColumn.columns.map((col) => col.id);

          let isAllFileSubmit = true;
          Object.keys(updatedRow).forEach((key) => {
            if (documentsId.includes(key)) {
              if (updatedRow[key].status !== "제출완료" && key !== "docu5") {
                isAllFileSubmit = false;
              }
            }
          });

          if (isAllFileSubmit) {
            updatedDocuAll.status = "제출완료";
          }

          updatedRow["docu-allSubmit"] = updatedDocuAll;
          updatedData[rowNum] = updatedRow;
          participantsData[idArray[4]] = updatedData;

          setParticipants({ ...participantsData });
        } else {
          setError({
            title: `파일 업로드 실패`,
            detail: responseData.message,
          });
        }
      } catch (error) {}
    },
    [participants, auth.token, auth.universityName, sendRequest, setError]
  );

  const submitMultipleFileHandler = useCallback(
    async (files, id) => {
      const filesArray = Array.from(files);

      filesArray.forEach((file) => {
        const { result, message } = checkValidityFileMultiple(file);
        if (!result) {
          setError({ title: "파일정보 확인", detail: message });
          return;
        }
      });

      const idArray = id.split("-");
      const rowNum = Number(idArray[0].replace("row", ""));

      try {
        let formData = new FormData();
        const participant = participants[idArray[4]][rowNum];
        formData.append("participantId", participant.participantId);

        const fileInfos = {
          fileInfos: filesArray.map((file) => {
            const docuName = file.name.split(/_|\./)[1].normalize("NFC");
            const docuIdx = documentsColumn.columns.find(
              (col) => col.name === docuName
            ).id;
            return {
              fileId: participant[docuIdx].fileId,
              fileName: auth.universityName + "_" + file.name.normalize("NFC"),
            };
          }),
        };

        formData.append(
          "fileInfos",
          new Blob([JSON.stringify(fileInfos)], { type: "application/json" })
        );

        for (const file of files) {
          formData.append(
            "files",
            file,
            auth.universityName + "_" + file.name.normalize("NFC")
            // file.name.split(/_|\./)[1].normalize("NFC")
          );
        }

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/file`,
          "POST",
          formData,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          `파일 업로드 실패`
        );

        // // DUMMY DATA
        // const responseData = {
        //   isSuccess: true,
        //   message: null,
        //   payload: {
        //     fileInfos: [
        //       {
        //         fileId: 100,
        //         fileName: "서약서",
        //       },
        //       {
        //         fileId: 200,
        //         fileName: "선수등록이력",
        //       },
        //       {
        //         fileId: 110,
        //         fileName: "증명사진",
        //       },
        //     ],
        //   },
        // };

        if (responseData.isSuccess) {
          let participantsData = JSON.parse(JSON.stringify(participants));

          let updatedData = [...participantsData[idArray[4]]];
          let updatedRow = { ...updatedData[rowNum] };
          let updatedDocuAll = { ...updatedRow["docu-allSubmit"] };

          const fileNames = filesArray.map((file) =>
            file.name.normalize("NFC")
          );

          responseData.payload.fileInfos.forEach((fileInfo) => {
            const { fileId, fileName } = fileInfo;
            const docuIdx = documentsColumn.columns.find((col) =>
              fileName.normalize("NFC").includes(col.name)
            ).id;

            let updatedDocu = { ...updatedRow[docuIdx] };
            updatedDocu.status = "제출완료";
            updatedDocu.fileId = fileId;

            // 파일 제출된거는 파일이름 표시하고
            const updatedFileNameSplitArray = fileName
              .normalize("NFC")
              .split("_");
            updatedDocu.text = fileNames.find((file) =>
              file
                .normalize("NFC")
                .includes(
                  updatedFileNameSplitArray[1] +
                    "_" +
                    updatedFileNameSplitArray[2]
                )
            );
            updatedRow[docuIdx] = updatedDocu;
          });

          // 일괄제출 아래에 몇개 제출됐는지 표시, 모든 파일 제출했는지 체크
          const documentsId = documentsColumn.columns.map((col) => col.id);

          let isAllFileSubmit = true;
          Object.keys(updatedRow).forEach((key) => {
            if (documentsId.includes(key)) {
              if (updatedRow[key].status !== "제출완료" && key !== "docu5") {
                isAllFileSubmit = false;
              }
            }
          });

          if (isAllFileSubmit) {
            updatedDocuAll.status = "제출완료";
          }
          updatedDocuAll.text = `${filesArray.length}개 파일제출`;

          updatedRow["docu-allSubmit"] = updatedDocuAll;
          updatedData[rowNum] = updatedRow;
          participantsData[idArray[4]] = updatedData;

          setParticipants(participantsData);
        } else {
          setError({
            title: `파일 업로드 실패`,
            detail: responseData.message,
          });
        }
      } catch (error) {}
    },
    [participants, auth.universityName, auth.token, sendRequest, setError]
  );

  useEffect(() => {
    listHandler();
  }, [listHandler]);

  return (
    <div className="docu-submit">
      <h2 className="docu-submit-title">서류제출</h2>
      <div className="docu-submit-noti">{notification}</div>
      <div className="docu-tables-wrap">
        <div>
          <div className="docu-table-subtitle">선수,세컨</div>
          <DocuSubmitTable
            columns={colInfo}
            data={participants.sunsuSeconds}
            onFileSubmit={submitOneFileHandler}
            onFilesSubmit={submitMultipleFileHandler}
            type="sunsuSeconds"
            showNumber
          />
        </div>
        <div>
          <div className="docu-table-subtitle">자원봉사자</div>
          <DocuSubmitTable
            columns={colInfoVolunteer}
            data={participants.volunteers}
            onFileSubmit={submitOneFileHandler}
            onFilesSubmit={submitMultipleFileHandler}
            type="volunteers"
            showNumber
          />
        </div>
      </div>
    </div>
  );
};

export default DocuSubmit;
